import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Archive from '../components/archive'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Footer from '../components/footer'
import {toPlainText} from '../lib/helpers'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'

export const query = graphql`
  query ArchiveQuery($id: String!) {
    page: sanityPage(id: {eq: $id}) {
      id
      publishedAt
      categories {
        _id
        title
      }
      mainImage {
        ...SanityImage
        alt
      }
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: {maxDepth: 5})
      _rawBody(resolveReferences: {maxDepth: 5})
      authors {
        _key
        author {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
        }
      }
    }
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
          categories {
            title
          }
        }
      }
    }
  }
`

const ArchiveTemplate = props => {
  const {data, errors} = props
  const postNodes = data && data.posts && mapEdgesToNodes(data.posts)
  const page = data && data.page
  return (
    <Layout>
      {errors && <SEO title='GraphQL Error' />}
      {page && <SEO title={page.title || 'Untitled'} description={toPlainText(page._rawExcerpt)} image={page.mainImage} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {page && <Archive {...page} />}
      <Container>
        {postNodes.length > 0 && <BlogPostPreviewList nodes={postNodes} />}
      </Container>
    </Layout>
  )
}

export default ArchiveTemplate
